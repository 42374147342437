import Input from '@appfolio/react-gears/lib/components/Input';
import Tooltip from '@appfolio/react-gears/lib/components/Tooltip';
import classNames from 'classnames';
import React, { useState } from 'react';
import uniqid from 'uniqid';

interface Props {
  value?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  draggable?: boolean;
  invalid?: boolean;
  valid?: boolean;
  fixedDimensions: {
    width: number;
    height: number;
  };
  backgroundColor?: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  placeholder?: string;
}

const YELLOW = 'rgba(252, 239, 220, .9)';

const CHAR_SIZE = 12;

const calculateCharacterLimit = (w: number, h: number): number => {
  return Math.floor((w * h) / Math.pow(CHAR_SIZE, 2));
};

const PdfTextbox: React.FC<Props> = ({
  value,
  disabled,
  onChange,
  draggable,
  invalid,
  valid,
  fixedDimensions,
  backgroundColor,
  tooltip,
  placeholder,
  required,
}: Props) => {
  const maxCount = calculateCharacterLimit(
    fixedDimensions.width,
    fixedDimensions.height
  );
  const [targetId] = useState(() => `a${uniqid()}`);

  const style: React.CSSProperties = disabled
    ? {
        backgroundColor: backgroundColor || YELLOW,
        resize: 'none',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        fontSize: 14,
        position: 'relative',
      }
    : {
        backgroundColor: YELLOW,
        resize: 'none',
        height: '100%',
        width: '100%',
      };
  const limit = `Character limit: ${maxCount}`;

  return (
    <div
      className="pdf-textbox-container"
      style={{
        cursor: draggable ? 'move' : 'pointer',
      }}
      id={targetId}
    >
      <Tooltip target={targetId} placement="top">
        {tooltip ? (
          <>
            {tooltip} <br />
            {`(${limit})`}
          </>
        ) : (
          limit
        )}
      </Tooltip>
      {disabled ? (
        <div className="textbox-field-content pdf-input" style={style}>
          <div
            className="pl-2 pb-1"
            style={{ position: 'absolute', bottom: 0 }}
          >
            {value}
          </div>
        </div>
      ) : (
        <Input
          type="textarea"
          className={classNames('textbox-field-content pdf-input', {
            required: required,
          })}
          disabled={disabled}
          onChange={e => {
            if (e.target.value.length <= maxCount) {
              onChange && onChange(e.target.value);
            }
          }}
          invalid={invalid}
          valid={valid}
          onMouseDown={e => e.stopPropagation()}
          style={style}
          value={value}
          placeholder={placeholder}
        />
      )}
      {/* draggable layer to make element draggable when input is disabled for FireFox */}
      {disabled && <div className="draggable-layer" />}
    </div>
  );
};

export default PdfTextbox;
