import DateInput from '@appfolio/react-gears/lib/components/DateInput';
import Tooltip from '@appfolio/react-gears/lib/components/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import uniqid from 'uniqid';

type DateOrString = Date | string;
interface Props {
  value?: Date;
  disabled?: boolean;
  onChange?: (event: any) => void;
  draggable?: boolean;
  maxCount: number;
  placeholder: string;
  parse?: (value: string, dateFormat: string) => DateOrString;
  invalid?: boolean;
  tooltip?: React.ReactNode;
}

const YELLOW = 'rgba(252, 239, 220, .9)';

const PdfDateInput: React.FC<Props> = ({
  value,
  disabled,
  onChange,
  draggable,
  placeholder,
  invalid,
  parse,
  tooltip,
}: Props) => {
  const [targetId] = useState(() => `pdf-date-input${uniqid()}`);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const inputEl = ref.current.getElementsByTagName('input')[0];
      inputEl.className += ' pdf-input';
    }
  }, [ref]);

  return (
    <div
      style={{
        cursor: draggable ? 'move' : 'pointer',
      }}
      className="pdf-date-input-container"
      ref={ref}
      id={targetId}
    >
      {tooltip && (
        <Tooltip target={targetId} placement="top">
          {tooltip}
        </Tooltip>
      )}
      <DateInput
        disabled={disabled}
        onChange={v => {
          onChange && onChange(v);
        }}
        onMouseDown={e => e.stopPropagation()}
        placeholder={placeholder}
        style={{
          backgroundColor: YELLOW,
        }}
        value={value}
        onPaste={e => e.preventDefault()}
        className={`${invalid === true ? 'is-invalid ' : ''}pdf-date-input`}
        parse={parse}
        autoComplete="off"
      />
    </div>
  );
};

export default PdfDateInput;
